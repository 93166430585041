import React from 'react';
import { graphql } from 'gatsby';
import { Container } from 'react-bootstrap';
import Img from 'gatsby-image';

import Layout from '../components/Layout';
import SEO from '../components/Seo';
import HeroImage from '../components/HeroImage';

export const query = graphql`
  query {
    backgroundImg: file(relativePath: { eq: "default-background.jpg" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 4200) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    architecture: file(relativePath: { eq: "architecture.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;

const over = ({ data }) => {
  return (
    <Layout pageInfo={{ pageName: 'over' }}>
      <SEO title="Over" />
      <HeroImage img={data.backgroundImg.childImageSharp.fluid}>
        <h1 className="text-white">Over Ons</h1>
      </HeroImage>
      <Container>
        <h1>Gatsby e-commerce proof of concept</h1>
        <p>
          Gatsby site:&nbsp;
          <a href="https://gatsby-store-poc.onrender.com">
            https://gatsby-store-poc.onrender.com
          </a>
        </p>
        <p>
          Nuxt site:&nbsp;
          <a href="https://nuxt-store-poc.onrender.com">
            https://nuxt-store-poc.onrender.com
          </a>
        </p>
        <h1>Technology stack</h1>
        <ul>
          <li>
            <a href="https://www.algolia.com/">Algolia</a>
          </li>
          <li>
            <a href="https://commentbox.io/">CommentBox</a>
          </li>
          <li>
            <a href="https://www.contentful.com/">Contentful</a>
          </li>
          <li>
            <a href="https://www.gatsbyjs.org/">Gatsby.js</a>
          </li>
          <li>
            <a href="https://gitlab.com/">GitLab</a>
          </li>
          <li>
            <a href="https://www.netlify.com/products/functions/">
              Netlify Functions
            </a>
          </li>
          <li>
            <a href="https://render.com/">Render</a>
          </li>
          <li>
            <a href="https://sendgrid.com/">Sendgrid</a>
          </li>
          <li>
            <a href="https://snipcart.com/">Snipcart</a>
          </li>
        </ul>
        <div style={{ maxWidth: '562px', padding: '1.5rem 0' }}>
          <Img
            fluid={data.architecture.childImageSharp.fluid}
            alt="Architecture"
          />
        </div>
        <h1>Afbeeldingen</h1>
        <p>
          Bron: <a href="https://www.freepik.com/">Freepik</a>
        </p>
      </Container>
    </Layout>
  );
};

export default over;
